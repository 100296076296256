import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { Stack, Container } from '@mui/system';
import { Dialog, Typography } from '@mui/material';

import { financeApi } from 'src/api/finance';
import { useAuthContext } from 'src/auth/hooks';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSwitch, RHFTextField } from 'src/components/hook-form';
import { RHFSelectMenu } from 'src/components/hook-form/rhf-select-menu';

const currenciesList = [
  {
    value: 1,
    label: "Dollar",
    icon: "openmoji:flag-united-states",
  },
  {
    value: 2,
    label: "Euro",
    icon: "flag:eu-4x3",
  },
  {
    value: 3,
    label: "Pound",
    icon: "fxemoji:greatbritainflag",
  },
  {
    value: 4,
    label: "Canadian dollar",
    icon: "openmoji:flag-canada",
  },
  {
    value: 5,
    label: "Australian dollar",
    icon: "openmoji:flag-australia"
  }
];

export const TraderAccountCreate = ({ open, onClose, onGetAccounts, isEdit = false, account = {} }) => {
  const { user } = useAuthContext();
  const methods = useForm({
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
  } = methods;

  useEffect(() => {
    if (account && isEdit) {
      setValue("name", account?.name);
    }
    if (open && !isEdit) {
      reset();
    }
  }, [account, isEdit, open]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const request = {
        ...data,
      };
      if (isEdit) {
        await financeApi.updateTradingAccount(account?.id, request);
      } else {
        await financeApi.createTradingAccount(request);
      }
      enqueueSnackbar(`Account ${isEdit ? 'updated' : 'created'} successfully!`);
      onClose();
      reset();
      setTimeout(() => {
        onGetAccounts();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        name="name"
        label="Name"
      />

      {!isEdit && (
        <RHFSelectMenu
          control={control}
          name="currency"
          label="Currency"
          list={currenciesList}
          isIcon
        />
      )}

      {!isEdit && <RHFSwitch name="demo" label="Demo" />}
      

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {isEdit ? 'Update' : 'Create'} account
      </LoadingButton>
    </Stack>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Container maxWidth="md">
        <Stack sx={{ px: 8, py: 5 }} spacing={5}>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 600,
              color: '#00A76F',
            }}
          >
            {isEdit ? 'Update' : 'Create'} trader account
          </Typography>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderForm}
          </FormProvider>
        </Stack>
      </Container>
    </Dialog>
  );
};
