import { alpha } from '@mui/material/styles';


export const getGlobalStyles = (theme) => ({
  '&#chartcontrols': {
    flexWrap: 'wrap !important'
  },
  '&.am5-modal-primary': {
    backgroundColor: `${theme.palette.success.main} !important`,
    border: `0 !important`,
    margin: ` 0.3rem 0.9rem !important`,
    width: '80px !important',
  },
  '&.am5-modal-primary:hover': {
    backgroundColor: `${theme.palette.success.dark} !important`,
  },
  '&.am5-modal-scondary': {
    border: `0 !important`,
    margin: ` 0.3rem 0.9rem !important`,
    width: '80px !important'
  },
  '&.am5-modal-curtain': {
    backgroundColor: `rgba(255, 255, 255, 0.1) !important`
  },
  '&.am5-modal-wrapper': {
    backgroundColor: `rgba(255, 255, 255, 0.1) !important`
  },
  '&.am5stock-control-list-container': {
    borderRadius: `13px !important`,
    backgroundColor: `${theme.palette.background.paper} !important`
  },
  '&.am5-modal-content': {
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.text.primary} !important`,
    paddingLeft: `3rem !important`,
    paddingRight: `3rem !important`,
    borderRadius: `10px !important`
  },
  '&.am5-modal-table-cell': {
    paddingLeft: `30px !important`,
    '.am5stock-control-button': {
      backgroundColor: 'inherit !important',
      '.am5stock-control-icon': {
        borderRadius: `25% !important`
      }
    },
    '.am5stock-control-button-active': {
      color: 'black !important'
    }
  },
  '&.am5-modal-table-heading': {
    padding: `5px 4px 3px 30px !important`
  },
  '&.am5stock-control-list-arrow': {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
  '&.am5stock-control-list': {
    backgroundColor: theme.palette.background.paper,
    'li': {
      padding: `5px 5px !important`
    }
  },
  '&.am5stock-control-list::-webkit-scrollbar': {
    width: '7px',
  },
  '&.am5stock-control-list::-webkit-scrollbar-track': {
    background: alpha(theme.palette.grey[600], 0.22),
  },
  '&.am5stock-control-list::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.grey[600], 0.55),
    borderRadius: 4
  },
  '&.am5stock-control-list::-webkit-scrollbar-thumb:hover': {
    background: alpha(theme.palette.grey[600], 0.62),
  },
  '&.am5stock-list-info': {
    color: `${theme.palette.text.primary} !important`,
  },
  '&.am5stock-list-search': {
    paddingTop: `10px !important`,
    'input': {
      borderRadius: `10px !important`,
    }
  },
  '.am5stock-list-item': {
    cursor: 'pointer',
    borderRadius: 13,
    backgroundColor: `${theme.palette.background.primary} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.background.neutral} !important`,
      // 'label': {
      //   color: `${theme.palette.text.primary} !important`,
      // }
    },
    'label': {
      color: `${theme.palette.text.primary} !important`,
    }
  },
  '&.am5stock-control-button': {
    cursor: 'pointer !important',
    borderRadius: `10px !important`,
    opacity: 1,
    backgroundColor: `${theme.palette.background.neutral} !important`,
    border: `0 !important`,
    padding: '3px !important',
    marginLeft: '3px !important',
    marginRight: '3px !important',
    fontSize: `14px !important`,
    color: `${theme.palette.text.primary} !important`,
    'path': {
      stroke: `${theme.palette.text.secondary} !important`,
      strokeWidth: `1.3 !important`
    }
  },
  '&.am5stock-control-button-active': {
    'path': {
      stroke: theme.palette.mode === 'dark'? `#ffffff !important`: `#089981 !important`,
      strokeWidth: '1.3 !important'
    },
    backgroundColor: `rgba(0, 167, 111, 0.08) !important`,
    color: `#00A76F !important`,
    '.am5stock-control-icon': {
      backgroundColor: theme.palette.mode === 'dark'? '#00A76F !important':'#d6f1e8 !important',
      borderRadius: '20% !important',
      padding: '3px'
    }
  },
  '.am5stock-control-icon': {
    // backgroundColor: 'red !important',
    // borderRadius: '50% !important',
    padding: '3px',
    '.am5stock-control-icon-color-bg': {
      width: '0px !important',
      zIndex: '999999999999999999999 !important'
    },
  },
  '&.am5stock-control-label': {
    userSelect: 'none !important'
  }
})