import { Icon } from '@iconify/react';

import { Box, Modal, Typography, IconButton } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  p: 4,
  borderRadius: 3,
  border: '1px solid rgba(0, 0, 0, 0.08)',
};

export default function QuantumEdgeModal({ open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="quantum-edge-modal"
    >
      <Box sx={style}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
            '&:hover': {
              color: 'grey.700',
            },
          }}
          aria-label="close"
        >
          <Icon icon="mdi:close" width="20" height="20" />
        </IconButton>
        <Typography 
          id="quantum-edge-modal-description"
          sx={{
            textAlign: 'center',
            color: 'text.primary',
            fontSize: '1rem',
            lineHeight: 1.6,
            letterSpacing: '0.00938em',
            padding: '12px',
            paddingTop: '24px',
            '& span': {
              fontWeight: 600,
              color: 'primary.main',
            }
          }}
        >
          This product was developed by <span>Quantum Edge LTD</span>. Quantum Edge LTD is not affiliated with, nor responsible for, the conduct, performance, or practices of any third-party brokers using this software.
        </Typography>
      </Box>
    </Modal>
  );
} 