import * as yup from 'yup';
import SignaturePad from "react-signature-canvas";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useMemo, useState, useEffect } from 'react';

import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

import { formsApi } from 'src/api/forms';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { LeftIcon, RightIcon } from 'src/components/carousel/arrow-icons';

export const FormModal = ({ formId = -1, settings, formName, open, onClose, currentFormIndex, formListCount, handleNextForm, handlePrevForm, handleSubmittedForm }) => {
  const brandId = localStorage.getItem("brandId");

  const [signatures, setSignatures] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [isSubmitting, setIsSubmitting]= useState(false);
  const sigCanvas = useRef({});
  const { enqueueSnackbar } = useSnackbar();

  const  validationObject = useMemo(()=> {
    let yupObj = {} ;
    settings?.filter((item)=> !item?.isOptional)?.forEach((item)=> {
      yupObj= {
        ...yupObj,
        [item?.id] : item?.inputType !==3 ? yup.string().required('This field is required.') : yup.array().min(1, "This field is required.").of(yup.string().required('Item is required'))
      }})
    return yupObj;
  }, [settings])

  const validationSchema = yup.object({
    ...validationObject
  })

  const { register, handleSubmit, reset, control, formState: { errors }, setValue, setError, clearErrors } = useForm({ resolver: yupResolver(validationSchema)});

  useEffect(() => {
    if(settings?.length) {
      settings?.filter((item)=> !item?.isOptional)?.forEach((item) => {
        setValue(item.id, item?.inputType !==3? "": [])
      })
    } else handlePrevForm();
  }, [settings])

  const onSubmit = (data)=> {
    setIsSubmited(true);

    const formData = new FormData();
    formData.append('internal_brand_id', brandId);
    formData.append('brand_form_id', formId);

    const result = settings.map((setting) => ({
      ...setting,
      value: setting.inputType === 4 ? (signatures[setting.id] ?? "") : (data[setting.id] ?? "")
    }));

    formData.append('settings', JSON.stringify(result));
    setIsSubmitting(true);

    formsApi.submitForm(formData).then(() => {
      handleSubmittedForm(formId);
      enqueueSnackbar('Form is submitted successfully.', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }).finally(()=> {
      setIsSubmitting(false);
    });
  }

  const onSubmitErrorHandler = () => {
    setIsSubmited(true);
    settings?.forEach((setting) => {
      if (!setting?.isOptional && (setting.inputType === 4 || setting.inputType === 3)) {
        if (!signatures[setting.id]) {
          setError(setting.id, { type: 'custom', message: 'The field is required' });
        } else{
        clearErrors(setting.id);
        setValue(setting.id, '_');
        }
      }
    });
  }

  useEffect(() => {
    settings.forEach((setting) => {
      if (setting.inputType === 4 && !setting?.isOptional) {
        if (!signatures[setting.id]) {
          setValue(setting.id, '');
        } else {
          clearErrors(setting.id);
          setValue(setting.id, '_');
        }
      }
    });
  }, [signatures, isSubmited, settings]);

  const onClearCanvas = (id) => {
    if (signatures[id]) {
      sigCanvas.current[id].clear();
      setSignatures((prev) => ({ ...prev, [id]: null }));
      setError(id, { type: 'custom', message: 'The field is required' });
  }}

  const onSaveCanvas = (id) => {
    setSignatures((prev) => ({ ...prev, [id]: sigCanvas.current[id].toDataURL() }));
  }

  useEffect(() => {
    setIsSubmited(false);
    setSignatures({});
    reset();
  }, [formId])

  return (
    <Dialog open={open} onClose={onClose} fullWidth className='form-modal'>
      <Stack sx={{ px: 4, minHeight: "none", justifyContent: 'space-between' }} spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}  sx={{mt: 8, mb:1}}>
          <Stack alignItems="flex-end">
            <Typography variant="h4">Fill the forms and submit</Typography>
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit, onSubmitErrorHandler)} style={{ height: '100%' }}>
          <Stack direction="column" sx={{ minHeight: 480, flexGrow: 1, mx: 2}}>
            <Typography variant="h5" px={1}>{formName??""}</Typography>
            <Scrollbar sx={{ maxHeight: 450, px:1 }}>
              {settings?.map((setting) => (
                <Stack key={setting.id}>
                  <Typography marginTop={3}>{setting.name}</Typography>
                  {setting.inputType === 1 && (
                    <TextField
                      variant="standard"
                      sx={{ maxWidth: '100%', width: 1 }}
                      {...register(setting?.id)}
                      error={!!errors?.[setting?.id]?.message}
                      helperText={errors?.[setting?.id]?.message}
                    />
                  )}
                  {setting.inputType === 2 && (
                    <Stack key={setting.id}>
                      <Controller
                        control={control}
                        name={setting?.id}
                        render={({ field: { onChange } }) => (
                          <RadioGroup
                            sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                            name={setting.id}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {setting.options.map((option) => (
                              <FormControlLabel
                                key={option.id}
                                control={
                                  <Radio
                                    size="normal"
                                    value={option.id}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                                  />
                                }
                                label={option.option}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                      {errors?.[setting?.id]?.message ? (
                        <FormHelperText error={!!errors?.[setting?.id]?.message} sx={{ m: 0 }}>
                          {errors?.[setting?.id]?.message ?? ''}
                        </FormHelperText>
                      ) : null}
                    </Stack>
                  )}
                  {setting.inputType === 3 && (
                    <Stack key={setting.id}>
                      <FormGroup>
                        <Stack direction="row" gap={0.5} sx={{ flexWrap: 'wrap' }}>
                          <Controller
                            control={control}
                            name={setting?.id}
                            render={({ field: { value = [], onChange } }) => (
                              <>
                                {setting.options.map((option) => (
                                  <FormControlLabel
                                    key={option.id}
                                    control={
                                      <Checkbox
                                        size="medium"
                                        checked={value?.includes(option?.id)}
                                        onChange={(e) => {
                                          const newValue = e.target.checked
                                            ? [...value, option.id]
                                            : value?.filter((v) => v !== option.id);
                                          onChange(newValue);
                                        }}
                                      />
                                    }
                                    label={option.option}
                                  />
                                ))}
                              </>
                            )}
                          />
                        </Stack>
                      </FormGroup>
                      {errors?.[setting?.id]?.message ? (
                        <FormHelperText error={!!errors?.[setting?.id]?.message} sx={{ m: 0 }}>
                          {errors?.[setting?.id]?.message ?? ''}
                        </FormHelperText>
                      ) : null}
                    </Stack>
                  )}
                  {setting.inputType === 4 && (
                    <Stack>
                      <Stack
                        sx={{
                          position: 'relative',
                          border: errors?.[setting?.id]?.message
                            ? 'solid 1px #EF4444'
                            : 'solid 1px #eee',
                          backgroundColor: '#FFF',
                        }}
                      >
                        <SignaturePad
                          ref={(el) => {
                            sigCanvas.current[setting.id] = el;
                          }}
                          onEnd={() => onSaveCanvas(setting.id)}
                        />
                        {signatures[setting.id] && (
                          <IconButton
                            onClick={() => onClearCanvas(setting.id)}
                            sx={{ position: 'absolute', right: 0, bottom: 0 }}
                          >
                            <Iconify icon="mingcute:eraser-line" width={24} />
                          </IconButton>
                        )}
                      </Stack>
                      {errors?.[setting?.id]?.message ? (
                        <FormHelperText error={!!errors?.[setting?.id]?.message} sx={{ m: 0 }}>
                          {errors?.[setting?.id]?.message ?? ''}
                        </FormHelperText>
                      ) : null}
                    </Stack>
                  )}
                </Stack>
              ))}
            </Scrollbar>
          </Stack>
          <Stack justifySelf="center">
            <LoadingButton
              loading={isSubmitting}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ width: 150, ml: 'auto' }}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{mb: 4}}>
          <Button onClick={handlePrevForm} disabled={currentFormIndex === 0}>
            <LeftIcon />
          </Button>
          {`${currentFormIndex + 1} / ${formListCount}`}
          <Button onClick={handleNextForm} disabled={currentFormIndex >= formListCount - 1}>
            <RightIcon />
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
