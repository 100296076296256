import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';

import useDomainInfo from 'src/hooks/use-domain-info';
import { useGetFavicon } from 'src/hooks/use-favicon';
import { useResponsive } from 'src/hooks/use-responsive';

import { getAPIUrl } from 'src/config';
import { bgGradient } from 'src/theme/css';

import Logo from 'src/components/logo';
import QuantumEdgeModal from 'src/components/QuantumEdgeModal';

// ----------------------------------------------------------------------

const METHODS = [
  {
    id: 'jwt',
    label: 'Jwt',
    path: paths.auth.jwt.login,
    icon: '/assets/icons/auth/ic_jwt.svg',
  },
  {
    id: 'firebase',
    label: 'Firebase',
    path: paths.auth.firebase.login,
    icon: '/assets/icons/auth/ic_firebase.svg',
  },
  {
    id: 'amplify',
    label: 'Amplify',
    path: paths.auth.amplify.login,
    icon: '/assets/icons/auth/ic_amplify.svg',
  },
  {
    id: 'auth0',
    label: 'Auth0',
    path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/ic_auth0.svg',
  },
  {
    id: 'supabase',
    label: 'Supabase',
    path: paths.auth.supabase.login,
    icon: '/assets/icons/auth/ic_supabase.svg',
  },
];

export default function AuthClassicLayout({ children, image, title }) {

  const { domainData, isDomainLoading } = useDomainInfo();
  useGetFavicon();

  const themeSetting = useMemo(() => {
    if (domainData?.internal_brand) {
      const s = domainData?.internal_brand?.theme_setting
        ? JSON.parse(domainData?.internal_brand?.theme_setting)
        : {};
      return s;
    }
    return null;
  }, [domainData?.internal_brand]);

  const [isValid, setIsValid] = useState(false);
  const checkImageValidity = () => {
    const img = new Image();
    img.src = domainData?.internal_brand?.welcome_image;

    img.onload = () => {
      setIsValid(true);
    };

    img.onerror = () => {
      setIsValid(false);
    };
  };

  useEffect(() => {
    if (domainData) {
      checkImageValidity();
    }
  }, [domainData]);

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
        maxWidth: 200,
        maxHeight: 150,
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 10 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        height: '100vh',
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Stack 
        alignItems="center" 
        spacing={10}
        sx={{ pt: 10 }}
      >
        {isDomainLoading ? null :
          <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
            {(themeSetting?.show_welcome_message || themeSetting?.show_welcome_message === undefined) ?
              (title ||
              (domainData?.internal_brand?.company_name
                ? `Welcome to ${domainData?.internal_brand?.company_name}`
                : 'Hi, Welcome back')): ""}
          </Typography>
        }

        {isValid ?
          <Box
            component="img"
            alt="auth"
            src={domainData?.internal_brand?.welcome_image ?
              domainData?.internal_brand?.welcome_image?.includes('http') ? domainData?.internal_brand?.welcome_image : `${getAPIUrl()}/${domainData?.internal_brand?.welcome_image}` :
              '/assets/illustrations/illustration_dashboard.png'
            }
            sx={{ maxWidth: 720 }}
          />
          :
          <Box
            component="img"
            alt="auth"
            src='/assets/illustrations/illustration_dashboard.png'
            sx={{ maxWidth: 720, opacity: 0 }}
          />
        }
      </Stack>

      <Typography variant="body2" sx={{ pb: 5 }}>
        Product by{' '}
        <Tooltip title="This product was developed by Quantum Edge LTD. Quantum Edge LTD is not affiliated with, nor responsible for, the conduct, performance, or practices of any third-party brokers using this software.">
          <Link
            component="span"
            sx={{ cursor: 'pointer' }}
            onClick={handleModalOpen}
          >
            Quantum Edge LTD
          </Link>
        </Tooltip>
      </Typography>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {mdUp && renderSection}

      {renderContent}

      <QuantumEdgeModal 
        open={modalOpen}
        onClose={handleModalClose}
      />
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
