import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import { Stack, Container } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

export const CURRENCIES = {
  1: '$',
  2: '€',
  3: '£',
  4: 'CA$',
  5: 'A$',
};

export const BalanceModal = ({ open, onClose, balance = {}, marginCall = false, currency }) => {
  const { user } = useAuthContext();

  return (
    <Dialog open={open} onClose={onClose}>

      {marginCall && (
        <Stack
          spacing={1}
          alignItems="center"
          textAlign="center"
          alignSelf="center"
          px={1}
          py={1}
          sx={{
            backgroundColor: '#EF4444CC',
            borderRadius: '12px',
            color: '#fff',
            mt: 8,
            maxWidth: '90%'
          }}
        >
          <SvgColor src="/assets/icons/components/ic_warning.svg" sx={{ width: 36, height: 36 }} />

          <Typography fontWeight={500} fontSize={18}>
            Margin call: Positions will close on certain margin level!
          </Typography>
        </Stack>
      )}

      <Container maxWidth="sm" sx={{ px: 13, py: 5 }}>
        <IconButton
          size="large"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <Iconify icon="material-symbols:close" width={28} />
        </IconButton>
        <Stack spacing={2} divider={<Divider />} sx={{ width: 1 }}>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <SvgColor
                src="/assets/icons/components/ic_balance.svg"
                sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: marginCall ? '#DC2626' : 'text.secondary' }}>
                Balance
              </Typography>
            </Stack>

            <Typography sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}>
              {currency}{' '}
              {balance?.balance ? balance.balance.toFixed(2) : '0.00'}
            </Typography>
          </Stack>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <SvgColor
                src="/assets/icons/components/ic_equity.svg"
                sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: marginCall ? '#DC2626' : 'text.secondary', whiteSpace:"nowrap" }}>
                Equity
              </Typography>
            </Stack>

            <Typography sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}>
              {currency}{' '}
              {balance?.equity ? balance.equity.toFixed(2) : '0.00'}
            </Typography>
          </Stack>

          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <SvgColor
                src="/assets/icons/components/ic_pl.svg"
                sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: marginCall ? '#DC2626' : 'text.secondary', whiteSpace:"nowrap" }}>
                Open P/L
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}>
                {currency}{' '}
                {balance?.pl ? balance.pl.toFixed(2) : '0.00'}
              </Typography>

              {balance?.pl > 0 ? (
                <Iconify icon="ph:arrow-up" width={18} color="#00A76F" />
              ) : (
                <Iconify icon="ph:arrow-down" width={18} color="#EF4444" />
              )}
            </Stack>
          </Stack>

          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <SvgColor
                src="/assets/icons/components/ic_margin.svg"
                sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: marginCall ? '#DC2626' : 'text.secondary', whiteSpace:"nowrap" }}>
                Margin level
              </Typography>
            </Stack>

            <Typography sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}>
              {balance?.margine_level ? balance.margine_level.toFixed(2) : '0.00'}%
            </Typography>
          </Stack>

          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <SvgColor
                src="/assets/icons/components/ic_free_margin.svg"
                sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: marginCall ? '#DC2626' : 'text.secondary', whiteSpace:"nowrap"}}>
                Free margin
              </Typography>
            </Stack>

            <Typography sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}>
              {currency}{' '}
              {balance?.free_margin ? balance.free_margin.toFixed(2) : '0.00'}
            </Typography>
          </Stack>

          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <SvgColor
                src="/assets/icons/components/ic_used_margin.svg"
                sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: marginCall ? '#DC2626' : 'text.secondary', whiteSpace:"nowrap" }}>
                Used margin
              </Typography>
            </Stack>

            <Typography sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}>
              {currency}{' '}
              {balance?.used_margin ? balance.used_margin.toFixed(2) : '0.00'}
            </Typography>
          </Stack>

          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <SvgColor
                src="/assets/icons/components/ic_credit.svg"
                sx={{ width: 18, height: 18, color: marginCall ? '#DC2626' : 'text.secondary' }}
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: marginCall ? '#DC2626' : 'text.secondary', whiteSpace:"nowrap" }}>
                Credit
              </Typography>
            </Stack>

            <Typography sx={{ fontSize: 16, fontWeight: 400, color: marginCall ? '#DC2626' : 'text.primary', whiteSpace:"nowrap" }}>
              {currency}{' '}
              {balance?.credit ? Number(balance.credit)?.toFixed(2) : '0.00'}
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Dialog>
  );
};
