import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import { useMounted } from 'src/hooks/use-mounted';
import { useBoolean } from 'src/hooks/use-boolean';
import { useGetFavicon } from 'src/hooks/use-favicon';
import useDomainInfo from 'src/hooks/use-domain-info';
import { useResponsive } from 'src/hooks/use-responsive';

import { authApi } from 'src/api/auth';
import { formsApi } from 'src/api/forms';

import { useSettingsContext } from 'src/components/settings';

import Main from './main';
import Header from './header';
import { FormModal } from './form-modal';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  
  const { domainData } = useDomainInfo();
  const [open, setOpen] = useState(true);
  const [formList, setFormList] = useState([]);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const isMounted = useMounted();

  const settings = useSettingsContext();
  useGetFavicon();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const handleNextForm = () => {
    if(currentFormIndex + 1 <= formList?.length) setCurrentFormIndex(prev => prev + 1);
  }

  const handlePrevForm = () => {
    if(currentFormIndex > 0) setCurrentFormIndex(prev => prev - 1);
  }

  const handleSubmittedForm = (formId) => {
    const updatedForms = formList.filter(form => form.id !== formId);
    setFormList(updatedForms ?? [])
  }

  const handleClose = () => {
    if (formList.length === 0) {
      setOpen(false);
    }    
  }

  useEffect(() => {
    if (!isMounted()) return;
    const defaultSound = "/assets/sounds/connect.wav";
    const audio = new Audio(defaultSound);
    setTimeout(() => {
      audio.play();
    }, 2500);
  }, [isMounted]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const modalExists = document.querySelector('.form-modal') !== null;
      if (!modalExists) {
        if(open) {
          setOpen(false);
        } else {
          setTimeout(()=> setOpen(true), 500)
        }
      } 
    }, 1000); 
    return () => clearInterval(interval); 
  }, [open]);

  const initializeForms = async () => {
    const clientRes = await authApi.getUser();
    const { client } = clientRes.data;
    const { filled_forms, required_forms } = client;
    const unfilled_forms = required_forms.filter(form => !filled_forms.includes(form));
  
    try {
      const forms = await Promise.all(
        unfilled_forms.map(async (unfilled_form) => {
          const formRes = await formsApi.getForm(unfilled_form);
          return { ...formRes.data.form, settings: JSON.parse(formRes.data.form?.settings)};
        })
      );
      setFormList(forms ?? [])
    } catch (error) {
      console.error("Error fetching forms:", error);
    }
  };

  useEffect(() => {
    initializeForms();
  }, [domainData]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
        { 
          formList?.length > 0 ?
            <FormModal 
              formId={formList[currentFormIndex]?.id} 
              settings={formList[currentFormIndex]?.settings ?? []} 
              open={open} 
              onClose={handleClose} 
              currentFormIndex={currentFormIndex}
              formListCount={formList?.length} 
              handleNextForm={handleNextForm} 
              handlePrevForm={handlePrevForm} 
              handleSubmittedForm={handleSubmittedForm}
              formName={formList[currentFormIndex]?.name}
            />
          : null
        }
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {renderNavVertical}

          <Main>{children}</Main>
        </Box>
        { 
          formList?.length > 0 ?
            <FormModal 
              formId={formList[currentFormIndex]?.id} 
              settings={formList[currentFormIndex]?.settings ?? []} 
              open={open} 
              onClose={handleClose} 
              currentFormIndex={currentFormIndex}
              formListCount={formList?.length} 
              handleNextForm={handleNextForm} 
              handlePrevForm={handlePrevForm} 
              handleSubmittedForm={handleSubmittedForm}
              formName={formList[currentFormIndex]?.name}
            />
          : null
        }
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
        { 
          formList?.length > 0 ?
            <FormModal 
              formId={formList[currentFormIndex]?.id} 
              settings={formList[currentFormIndex]?.settings ?? []} 
              open={open} 
              onClose={handleClose} 
              currentFormIndex={currentFormIndex}
              formListCount={formList?.length} 
              handleNextForm={handleNextForm} 
              handlePrevForm={handlePrevForm} 
              handleSubmittedForm={handleSubmittedForm}
              formName={formList[currentFormIndex]?.name}
            />
          : null
        }
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
