import { m } from 'framer-motion';
import { useMemo, useState, useEffect } from 'react';

import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import useDomainInfo from 'src/hooks/use-domain-info';

import { getAPIUrl } from 'src/config';
import { useTranslate } from 'src/locales';
import { financeApi } from 'src/api/finance';
import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { TraderAccountCreate } from './trader-account-create';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Settings',
    linkTo: paths.dashboard.user.account,
  },
];

const currencyToIcon = {
  1: 'openmoji:flag-united-states',
  2: 'flag:eu-4x3',
  3: 'fxemoji:greatbritainflag',
  4: 'openmoji:flag-canada',
  5: 'openmoji:flag-australia',
};

// ----------------------------------------------------------------------

export const useTraderAccounts = (customerId, setAccounts = () => {}) => {
  const [localAccounts, setLocalAccounts] = useState([]);

  const getAccounts = async () => {
    try {
      const {
        data: { trading_accounts },
      } = await financeApi.getTradingAccounts({ client_id: customerId });
      setAccounts(trading_accounts ?? []);
      setLocalAccounts(trading_accounts ?? []);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, [customerId]);

  return {
    getAccounts,
    accounts: localAccounts,
  };
};

export default function AccountPopover() {
  const router = useRouter();
  const { user, logout, setAccount, accounts, setAccounts, tradingAccount: acc } = useAuthContext();
  const { getAccounts } = useTraderAccounts(user?.id, setAccounts);

  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();
  const popover = usePopover();
  const { domainData } = useDomainInfo();

  const [tradingAccount, setTradingAccount] = useState();
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState();
  const handleToggleSetting = () => router.push(paths.dashboard.user.account);

  const handleLogout = async () => {
    try {
      logout();
      popover.onClose();
      router.push('/login');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  
  useEffect(() => {
    if (acc) {
      setTradingAccount(acc);
    } else {
      setTradingAccount(localStorage.getItem("trading_account") ?? null);
    }
  }, [acc]);

  const handleSetTradingAccount = (account) => {
    setTradingAccount(account);
    setAccount(account);
    enqueueSnackbar('Successfully switched to trader account!', { variant: 'success' });
  };

  const themeSetting = useMemo(() => {
    if (domainData?.internal_brand) {
      const s = domainData?.internal_brand?.theme_setting
        ? JSON.parse(domainData?.internal_brand?.theme_setting)
        : {};
      return s;
    }
    return null;
  }, [domainData?.internal_brand]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.avatar?.includes('http') ? user?.avatar : `${getAPIUrl()}/${user?.avatar}`}
          alt={user?.full_name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.full_name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 250, p: 0 }}>
        <Stack sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.full_name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>

          {domainData?.internal_brand?.account_type ? (
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              Account type: {user?.client_rank_text ?? ''}
            </Typography>
          ) : null}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem
            onClick={() => {
              popover.onClose();
              handleToggleSetting();
            }}
          >
            {t('Settings')}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {accounts?.length > 0 ? (
          <Stack sx={{ p: 2, pb: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Trading Accounts
            </Typography>
            
            {accounts?.map((account) => (
              <Stack 
                key={account?.id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  p: 1.5,
                  mb: 1,
                  borderRadius: 1,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out',
                  bgcolor: account?.id == tradingAccount ? (theme) => alpha(theme.palette.primary.main, 0.08) : 'background.paper',
                  border: (theme) => `1px solid ${account?.id == tradingAccount ? theme.palette.primary.main : theme.palette.divider}`,
                  '&:hover': {
                    bgcolor: (theme) => account?.id == tradingAccount 
                      ? alpha(theme.palette.primary.main, 0.12)
                      : alpha(theme.palette.grey[500], 0.08),
                  },
                }}
                onClick={() => handleSetTradingAccount(account?.id)}
              >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  <Avatar
                    sx={{ 
                      width: 32, 
                      height: 32,
                      bgcolor: (theme) => account?.demo 
                        ? alpha(theme.palette.warning.main, 0.1)
                        : alpha(theme.palette.success.main, 0.1),
                      color: (theme) => account?.demo ? theme.palette.warning.main : theme.palette.success.main
                    }}
                  >
                    <Iconify width={18} icon={currencyToIcon[account?.currency]} />
                  </Avatar>
                  
                  <Stack spacing={0.25}>
                    <Typography variant="subtitle2" noWrap>
                      {account?.name}
                    </Typography>
                    <Stack direction="row" spacing={0.5}>
                      {account?.demo && (
                        <Chip 
                          label="Demo" 
                          size="small" 
                          color="warning" 
                          variant="soft"
                          sx={{ height: 20, fontSize: 10 }}
                        />
                      )}
                      {account?.active && (
                        <Chip 
                          label="Live" 
                          size="small" 
                          color="success" 
                          variant="soft"
                          sx={{ height: 20, fontSize: 10 }}
                        />
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAccountToEdit(account);
                    setOpenCreateAccount(true);
                  }}
                  sx={{ 
                    width: 28, 
                    height: 28,
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    }
                  }}
                >
                  <Iconify icon="lucide:edit" width={16} />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        ) : null}

        {themeSetting?.enable_create_trader_account === undefined || themeSetting?.enable_create_trader_account ? (
          <Stack sx={{ px: 2, pb: 2 }}>
            <MenuItem 
              onClick={() => setOpenCreateAccount(true)}
              sx={{
                borderRadius: 1,
                justifyContent: 'center',
                color: 'primary.main',
                fontWeight: 'fontWeightMedium',
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              <Iconify icon="eva:plus-circle-outline" sx={{ mr: 1 }} />
              Create Account
            </MenuItem>
          </Stack>
        ) : null}

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('Logout')}
        </MenuItem>
      </CustomPopover>

      <TraderAccountCreate
        open={openCreateAccount}
        onClose={() => {
          setOpenCreateAccount(false);
          setAccountToEdit(null);
        }}
        onGetAccounts={getAccounts}
        isEdit={!!accountToEdit}
        account={accountToEdit}
      />
    </>
  );
}
